var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tab } from '@s8/react-component-library';
var NestedTabs = function (_a) {
    var value = _a.value, tabs = _a.tabs, onChange = _a.onChange, _b = _a.orientation, orientation = _b === void 0 ? 'horizontal' : _b, _c = _a.size, size = _c === void 0 ? 'small' : _c, className = _a.className, liClassName = _a.liClassName, itemClassName = _a.itemClassName, _d = _a.testId, testId = _d === void 0 ? 'tabs' : _d;
    var _e = useState([]), expandedParents = _e[0], setExpandedParents = _e[1];
    var findParentTabId = function (childId, tabs) {
        for (var _i = 0, tabs_1 = tabs; _i < tabs_1.length; _i++) {
            var tab = tabs_1[_i];
            if (tab.children) {
                var isChildPresent = tab.children.some(function (child) { return child.id === childId; });
                if (isChildPresent) {
                    return tab.id;
                }
            }
        }
        return null;
    };
    var handleParentClick = function (tabId) {
        if (expandedParents.includes(tabId)) {
            setExpandedParents(expandedParents.filter(function (id) { return id !== tabId; }));
        }
        else {
            setExpandedParents(__spreadArray(__spreadArray([], expandedParents, true), [tabId], false));
        }
    };
    useEffect(function () {
        var parentTabId = findParentTabId(value, tabs);
        if (parentTabId && !expandedParents.includes(parentTabId)) {
            setExpandedParents(__spreadArray(__spreadArray([], expandedParents, true), [parentTabId], false));
        }
    }, [value, tabs, expandedParents]);
    var renderChildren = function (children) {
        return (_jsx("ul", __assign({ className: "child-tabs ml-4" }, { children: children.map(function (child) { return (_jsx("li", __assign({ id: child.id, onClick: function () {
                    if (!child.disabled) {
                        onChange(child.id);
                    }
                }, className: clsx(liClassName, child.disabled ? 'cursor-default' : 'cursor-pointer') }, { children: _jsx(Tab, { testId: child.id, id: child.id, size: size, orientation: orientation, className: itemClassName, active: value === child.id, tab: child }) }), child.id)); }) })));
    };
    return (_jsx("ul", __assign({ className: clsx('tabs', className), "data-orientation": orientation, "data-size": size, "data-testid": testId }, { children: tabs.map(function (tab) { return (_jsxs("li", __assign({ id: tab.id, className: clsx(liClassName, tab.disabled ? 'cursor-default' : 'cursor-pointer', expandedParents.includes(tab.id) && tab.children ? 'expanded' : '') }, { children: [_jsx("div", __assign({ onClick: function () {
                        if (tab.children) {
                            handleParentClick(tab.id);
                        }
                        else if (!tab.disabled) {
                            onChange(tab.id);
                        }
                    }, className: clsx('parent-tab', {
                        'has-children': tab.children,
                    }) }, { children: _jsx(Tab, { testId: tab.id, id: tab.id, size: size, orientation: orientation, className: itemClassName, active: value === tab.id, tab: tab }) })), expandedParents.includes(tab.id) && tab.children && renderChildren(tab.children)] }), tab.id)); }) })));
};
export default NestedTabs;
