var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FlagIconFilled, FileIcon } from '@s8/react-component-library';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import NestedTabs from '../NestedTabs';
export default function DocsTabs() {
    var location = useLocation();
    var initialValue = location.search ? location.search.replace('?api=', '') : 'overview';
    var _a = useState(initialValue), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var valueToSet = location.search ? location.search.replace('?api=', '') : 'overview';
        setValue(valueToSet);
    }, [location]);
    var navigate = useNavigate();
    var tabs = [
        {
            id: 'overview',
            label: 'Overview',
            startIcon: _jsx(FlagIconFilled, {}),
            linkProps: { to: '/docs' },
        },
        {
            id: 'order-placement',
            label: 'Order Placement',
            startIcon: _jsx(FileIcon, {}),
            children: [
                {
                    id: 'order-placement-2-0-8',
                    label: 'Version 2.0.8',
                    startIcon: _jsx(FileIcon, {}),
                    linkProps: { to: '/docs?api=order-placement-2-0-8' },
                },
            ],
        },
        {
            id: 'customer-management',
            label: 'Customer Management',
            startIcon: _jsx(FileIcon, {}),
            children: [
                {
                    id: 'customer-management-2-0-6',
                    label: 'Version 2.0.6',
                    startIcon: _jsx(FileIcon, {}),
                    linkProps: { to: '/docs?api=customer-management-2-0-6' },
                }
            ],
        },
        {
            id: 'custody-services',
            label: 'Custody Services',
            startIcon: _jsx(FileIcon, {}),
            children: [
                {
                    id: 'custody-services-3-1-0',
                    label: 'Version 3.1.0',
                    startIcon: _jsx(FileIcon, {}),
                    linkProps: { to: '/docs?api=custody-services-3-1-0' },
                },
                {
                    id: 'custody-services-2-0-3',
                    label: 'Version 2.0.3',
                    startIcon: _jsx(FileIcon, {}),
                    linkProps: { to: '/docs?api=custody-services-2-0-3' },
                },
            ],
        },
    ];
    var handleChange = function (id) {
        setValue(id);
        navigate(__assign({ pathname: '/docs' }, (id !== 'overview' && {
            search: createSearchParams({
                api: id,
            }).toString(),
        })));
    };
    return (_jsx(NestedTabs, { value: value, tabs: tabs, orientation: "vertical-right", size: "medium", className: "w-60", onChange: function (id) { return handleChange(id); } }));
}
